@import "~/src/common/colors";

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.preloaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4rem;

  .preloader {
    height: 50px;
    width: 50px;
    border: 4px solid $light-gray;
    border-radius: 50%;
    border-top-color: $gray;

    animation: rotate 1s linear infinite;
  }
}
