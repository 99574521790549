import React from "react"
import cn from "classnames"

import ghSvg from "./svg/gh.svg"
import tgSvg from "./svg/tg.svg"
import vkSvg from "./svg/vk.svg"

import style from "./style.module.scss"

function Main() {
  return (
    <div className={style.content}>
      <div>
        <a href="https://github.com/gregorykirillov" className={style.socialItem}>
          <img height="50px" width="50px" src={ghSvg} alt="github" className={style.img} loading="lazy" />
        </a>
        <a href="https://t.me/g_kirillov" className={style.socialItem}>
          <img height="50px" width="50px" src={tgSvg} alt="telegram" className={style.img} loading="lazy" />
        </a>
        <a href="https://vk.com/vk.gregory" className={style.socialItem}>
          <img height="50px" width="50px" src={vkSvg} alt="vk" className={style.img} loading="lazy" />
        </a>
      </div>
      <div className={style.link}>
        <a href="https://budu.jobs/cv/p/kirillov" target="_self" className={style.btn}>
          CV
        </a>
      </div>
      <div className={cn(style.link, style.light)}>
        <a href="https://kirillov.us/cottage" target="_self" className={style.btn}>
          Cottage
        </a>
      </div>
    </div>
  )
}

export default Main
