import { mdIcon, ostrovokImg, avitoImg, bronevikImg } from "./svg"
import {
  edaImg,
  goyaevImg,
  hermitageImg,
  perekrestokImg,
  restoclubImg,
  sutochnoImg,
  vkusvillImg,
  yandexTravelImg
} from "./webp"

export const useContent = () => {
  const services = [
    {
      image: avitoImg,
      href: "https://www.avito.ru/kislovodsk/doma_dachi_kottedzhi/dom_200_m_na_uchastke_5_sot._2255805209?ysclid=m25yeoms94813021546"
    },
    {
      image: ostrovokImg,
      href: "https://ostrovok.ru/hotel/russia/kislovodsk/mid10480544/mountain_cottage_guest_house",
      customWidth: 60
    },
    {
      image: yandexTravelImg,
      href: "https://travel.yandex.ru/hotels/kislovodsk/mountain-cottage",
      customWidth: 80
    },
    {
      image: sutochnoImg,
      href: "https://kislovodsk.sutochno.ru/1442861",
      customWidth: 70
    },
    {
      image: bronevikImg,
      href: "https://bronevik.com/ru/hotel/russia/kislovodsk/mountain-cottage-mountain",
      customWidth: 60
    }
  ]

  const shops = [
    {
      name: "Яндекс Еда",
      imageSrc: edaImg,
      imageAlt: "Yandex eats logo",
      link: "https://eda.yandex.ru/",
      color: "#fce000"
    },
    {
      name: "Маркет Деливери",
      imageSrc: mdIcon,
      imageAlt: "Market delivery logo",
      link: "https://market-delivery.yandex.ru/",
      color: "#0ec645"
    },
    {
      name: "Перекресток",
      imageSrc: perekrestokImg,
      imageAlt: "Perekrestok logo",
      link: "https://www.perekrestok.ru/delivery/kislovodsk?modal=delivery",
      color: "rgb(12, 89, 46)"
    },
    {
      name: "Вкусвилл",
      imageSrc: vkusvillImg,
      imageAlt: "Vkusvill logo",
      link: "https://www.perekrestok.ru/delivery/kislovodsk?modal=delivery",
      color: "#2dbe64"
    }
  ]

  const restaurants = [
    {
      name: "Шафран",
      address: "Кирова 50",
      profileLink: "https://yandex.ru/maps/-/CCUVBAhjwA",
      imageSrc: goyaevImg,
      imageAlt: "Goyaev logo"
    },
    {
      name: "Resto & club",
      address: "Территориальная 2",
      profileLink: "https://yandex.ru/maps/-/CCUVBAXn0A",
      imageSrc: restoclubImg,
      imageAlt: "Restoclub logo"
    }
  ]

  const cafes = [
    {
      name: "Эрмитаж",
      address: "Дзержинского 30",
      profileLink: "https://yandex.ru/maps/-/CCUCm6W4lC",
      imageSrc: hermitageImg,
      imageAlt: "Hermitage logo"
    }
  ]

  return { services, shops, restaurants, cafes }
}
