.container {
  display: flex;
  flex-direction: column;

  width: 640px;

  gap: 16px;
  margin: 0 auto;

  text-align: justify;
}

@media all and (max-width: 992px) {
  .container {
    width: 720px;
  }
}

@media all and (max-width: 768px) {
  .container {
    width: 540px;
  }
}

@media all and (max-width: 576px) {
  .container {
    width: 320px;
    max-width: 90vw;
  }
}
