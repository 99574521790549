@import "~/src/common/colors";

.input {
  display: block;
  font-size: 16px;
  max-width: 100%;

  padding: 1px 7px 2px;

  border-color: $gray;
  border-style: solid;
  border-width: 1px;

  text-rendering: auto;
  text-align: start;
  color: initial;
  background: #fff;

  transition: 0.3s;
  cursor: pointer;

  &.sm {
    border-radius: 5px;
  }

  &.md {
    height: 45px;
    border-radius: 7px;
  }

  &:hover {
    filter: brightness(0.9);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px #a6bdee;
  }
}
