import React, { useContext, useEffect } from "react"
import cn from "classnames"

import { NotificationContext } from "~/src/App"

import style from "./style.module.scss"

type NotificationsType = "success" | "error" | "info"

type Props = {
  timeout?: number
  type?: NotificationsType
}

const Notifications = ({ timeout = 2, type = "success" }: Props) => {
  const { isNotifyActive, setIsNotifyActive, notificationText } = useContext(NotificationContext)

  useEffect(() => {
    if (isNotifyActive) {
      setTimeout(() => {
        setIsNotifyActive(false)
      }, timeout * 1000)
    }
  }, [isNotifyActive])

  return (
    <div className={style.container}>
      <div className={cn(style.notification, type && style[type], { [style.active]: isNotifyActive })}>
        {notificationText}
      </div>
    </div>
  )
}

export default Notifications
