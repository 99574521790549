$linkColor: #3a30c5;

.content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  margin: 35px 0;
  line-height: 40px;
}

p {
  font-size: 16px;
  line-height: 22px;
}

a[href] {
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

a[href]:focus-visible {
  box-shadow: 0 0 0 4px #a6bdee;
  outline: none;
}

.services {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.link {
  color: $linkColor;
  cursor: pointer;
  width: max-content;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 900px;
}

.card {
  width: 420px;
  max-width: 100%;
  box-shadow: 0px 0px 10px 0px rgb(64 64 64 / 30%);
  border-radius: 15px;

  transition: 0.25s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  border: 0;
  background: none;
  font-size: inherit;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgb(64 64 64 / 60%);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px #a6bdee;
    outline: none;
  }


  h3 {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: column;

    gap: 10px;
    padding: 15px;
  }
}

.serviceCard {
  width: 200px;
  height: 133px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 10px 0px rgb(64 64 64 / 30%);
  border-radius: 15px;

  transition: 0.25s ease-in-out;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgb(64 64 64 / 60%);
  }

  h3 {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: column;

    gap: 10px;
    padding: 15px;
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-height: 210px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.image {
  max-width: 50%;
}

.route {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

.map {
  height: 400px;
  width: 100%;
}

@media (max-width: 1000px) {
  .items {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .services {
    gap: 10px;
  }

  .serviceCard {
    height: 70px;
    width: 230px;
  }

  .map {
    width: 100%;
  }
}