import React, { createContext, Dispatch, SetStateAction, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { Cottage, Main } from "~/src/pages"
import NotFound from "../pages/NotFound"

import style from "./style.module.scss"

type NotificationType = {
  isNotifyActive: boolean
  setIsNotifyActive: Dispatch<SetStateAction<boolean>>
  notificationText: string
  setNotificationText: Dispatch<SetStateAction<string>>
}

export const NotificationContext = createContext<NotificationType>({} as NotificationType)

const App = () => {
  const [isNotifyActive, setIsNotifyActive] = useState(false)
  const [notificationText, setNotificationText] = useState("")

  return (
    <div className={style.container}>
      <NotificationContext.Provider
        value={{ isNotifyActive, setIsNotifyActive, notificationText, setNotificationText }}
      >
        <Routes>
          <Route index element={<Main />} />
          <Route path="cottage">
            <Route index element={<Cottage />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </NotificationContext.Provider>
    </div>
  )
}

export default App
