.container {
  display: flex;
  justify-content: center;

  position: fixed;
  top: 15px;
  left: 0;

  width: 100%;
}

.notification {
  width: max-content;
  padding: 7px 10px;
  
  transform: translateY(-100px);
  transition: transform 0.5s ease;
  
  top: 15px;
  left: 0;
  right: 0;
  
  font-size: 16px;
  box-shadow: 0px 0px 10px 0px rgba(64, 64, 64, 0.4);
  border-radius: 7px;
  
  &.success {
    color: #270;
    background-color: #fff;
    border: 1px solid #7fe340;
  }
  
  &.error {
    color: #D8000C;
    background-color: #FFBABA;
  }

  &.info {
    color: #059;
    background-color: #BEF;
  }

  &.active {
    transform: translateY(0px);
  }
}