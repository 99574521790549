import React from "react"
import { Link } from "react-router-dom"

import style from "./style.module.scss"

const NotFound = () => (
  <div className={style.content}>
    <h1>404</h1>
    <p style={{ paddingBottom: 30 }}>Page not found</p>
    <ul>
      <li>
        <Link to="/">Main page</Link>
      </li>
      <li>
        <Link to="/cottage">Cottage</Link>
      </li>
    </ul>
  </div>
)

export default NotFound
