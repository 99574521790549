import React, { useContext, useEffect } from "react"

import { Notifications } from "~/src/uikit"
import { NotificationContext } from "~/src/App"

import style from "./style.module.scss"
import { useContent } from "./useContent"

const Cottage = () => {
  const { services, shops, restaurants, cafes } = useContent()
  useEffect(() => {
    document.title = "Mountain Cottage"
  }, [])

  const handleCopyClick = (text: string) => () => {
    navigator.clipboard.writeText(text)

    setNotificationText("Скопировано")
    setIsNotifyActive(true)
  }
  const { setIsNotifyActive, setNotificationText } = useContext(NotificationContext)

  const renderCard = (cardInfo: {
    name: string
    address: string
    imageSrc: string
    imageAlt: string
    profileLink: string
  }) => {
    return (
      <div className={style.card}>
        <img src={cardInfo.imageSrc} alt={cardInfo.imageAlt} className={style.logo} loading="lazy" />
        <div className={style.content}>
          <h3>{cardInfo.name}</h3>
          <p>{cardInfo.address}</p>
          <a className={style.link} href={cardInfo.profileLink}>
            Яндекс профиль
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className={style.content}>
      <Notifications />
      <h1 className={style.title}>Сервисы бронирований</h1>
      <div className={style.services}>
        {services.map(service => (
          <a className={style.serviceCard} href={service.href} target="_blank">
            <img
              src={service.image}
              className={style.image}
              style={{ maxWidth: `${service.customWidth}%` }}
              loading="lazy"
            />
          </a>
        ))}
      </div>
      <h1 className={style.title}>Интернет</h1>
      <p>
        В доме две WiFi сети, 2.4GHz и 5GHz. 2.4GHz доступна во всем доме, но имеет среднюю скорость. 5GHz имеет высокую
        скорость, но меньший диапазон действия, отлично подходит для скачивания тяжелых файлов
      </p>
      <p onClick={handleCopyClick("Kislovodsk")}>
        Пароль: <b className={style.link}>Kislovodsk</b>
      </p>
      <h1 className={style.title}>Доставка продуктов</h1>
      <div className={style.items}>
        {shops.map(({ name, imageSrc, imageAlt, link, color }) => (
          <button key={name} className={style.card} onClick={() => window.open(link, "_blank")}>
            <div className={style.imageWrapper} style={{ background: color }}>
              <img className={style.image} src={imageSrc} alt={imageAlt} loading="lazy" />
            </div>
            <div className={style.content}>
              <h3>{name}</h3>
              <span>
                <p>Адрес для доставки:</p>
                <b className={style.link} onClick={handleCopyClick("Пятигорская 129А")}>
                  Пятигорская 129А
                </b>
              </span>
              <a className={style.link} href={link}>
                Перейти
              </a>
            </div>
          </button>
        ))}
      </div>
      <h1 className={style.title}>Рестораны</h1>
      <div className={style.items}>{restaurants.map(restaurant => renderCard(restaurant))}</div>
      <h1 className={style.title}>Кафе</h1>
      <div className={style.items}>{cafes.map(cafes => renderCard(cafes))}</div>
      <h1 className={style.title}>Маршруты</h1>
      <div className={style.route}>
        <p>Маршрут до верхнего парка (16 минут):</p>
        <a className={style.link} href="https://yandex.ru/maps/-/CCUVBEum-C">
          Яндекс карты
        </a>
      </div>
      <div className={style.route}>
        <iframe
          src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=88943133142"
          className={style.map}
          frameBorder="0"
        />
      </div>
    </div>
  )
}

export default Cottage
