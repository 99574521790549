@import "~/src/common/colors";

.button {
  display: inline-block;
  font-size: 16px;

  padding: 1px 7px 2px;

  border-color: $gray;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;

  text-rendering: auto;
  text-align: start;
  color: initial;
  background: #fff;

  transition: 0.3s;
  cursor: pointer;

  &.sm {
    border-radius: 5px;
  }

  &.md {
    height: 30px;
    border-radius: 7px;
  }

  &:hover {
    filter: brightness(0.9);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px #a6bdee;
  }

  &::after {
    display: none !important;
  }
}

.inline {
  font-size: 16px;
  border: 0;
  font-weight: 400;

  color: #005bff;
  background: none;
  cursor: pointer;

  transition: 0.3s;

  &:hover {
    color: #0042bd;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px #a6bdee;
  }
}
